//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { acceptAddNextNode } from '@/api/modular/fileStream/documentManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import { initWx } from '../components/minxin'
import selectLabelPer from '../components/selectLabelPer.vue'
import selectPerson from '../components/selectPerson'
export default {
    components: {
        OpenData,
        selectPerson,
        selectLabelPer,
    },
    mixins: [initWx],
    data() {
        return {
            list: [{ name: '節點', val: [] }],
            approvalId: '',
            loading: false,
            nodeId: '',
        }
    },
    created() {
        const { approvalId, nodeId } = this.$route.query
        this.nodeId = nodeId
        this.approvalId = approvalId
    },
    methods: {
        editDom() {
            const dom = arguments[0].target.parentElement.getElementsByClassName('input-dom')[0]
            dom.removeAttribute('readOnly')
            dom.classList.remove('input-dom-edit')
        },
        delDom(i) {
            this.list.splice(i, 1)
        },
        showSelectDialog(key, index) {
            this.$refs.selectPerson.open({ key, index })
        },
        objectEquail(obj1, obj2) {
            return JSON.stringify(obj1) === JSON.stringify(obj2)
        },
        selectTodoMsg({ type, data }) {
            if (type === 'book') {
                this.choosePerson(data.key, data.index)
            } else if (type === 'label') {
                this.$refs.selectLabelPer.open({ ...data })
            } else if (type === 'labelResult') {
                const { result, key, index } = data
                console.log(result, key, index)
                result.forEach((ele) => {
                    if (!this[key][index].val.some((j) => this.objectEquail(j, ele))) {
                        this[key][index].val.push(ele)
                    }
                })
            }
        },
        addDom() {
            this.list.push({ name: '節點', val: [] })
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'departmentId' : 'userId'
        },
        handleOk() {
            if (this.loading) return
            this.loading = true
            if (this.list.some((j) => !j.val || j.val.length === 0)) {
                this.$message.warning('存在節點沒有選擇人員！')
                return
            }
            const documentNodeParams = this.list.map((el, index) => ({
                billId: this.$route.query.id,
                sort: index,
                name: el.name,
                approvals: el.val.map(({ corpId, userId, id, type }) => ({
                    [this.getIdKey(type)]: userId || id,
                    corpId: corpId,
                })),
            }))
            acceptAddNextNode({
                documentNodeParams,
                approvalId: this.approvalId,
                nodeId: this.nodeId,
            })
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('添加成功')
                        this.$nextTick(() => {
                            this.$router.go(-2)
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.loading = true
                })
        },
        choosePerson(list, index) {
            const val = [...this[list][index].val]
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(list, index, val)
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    this.$set(this[list][index], 'val', [...result, ...resPart])
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}
